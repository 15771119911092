<template>
  <div class="sso-login-wrap">
    <div class="block-wrap">
      <div class="title-wrap">
        <div class="image">
          <img :src="banner" alt="" />
        </div>
      </div>
      <div v-if="errMsg" class="error-Msg">{{ errMsg }}</div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  // mapGetters,
  // mapMutations,
  mapActions
} from 'vuex'
import crypto from '../utils/crypto'
import { generateUUID } from '@/utils/lib.js'
import { apiSsoLogin, apiCheckStatus, apiErrorMsg } from '@/api/index.js'

export default {
  name: 'NpaLogin',
  components: {},
  props: {},
  data() {
    return {
      loguid: null,
      loginning: false,
      errMsg: null,
    }
  },
  computed: {
    ...mapState(['banner']),
  },
  watch: {},
  methods: {
    ...mapActions(['getWebAnnouncements', 'getUser', 'getSettingGeneral']),
    ...mapActions('global', ['fetchDataAfterLogin']),
    onLoad() {
      // const { /*protocol, host,*/ href } = window.location
      // const key = `?loguid=` // `${protocol}//${host}/npa_login?loguid=`;

      // const hrefs = href.split(key)
      // if (hrefs.length === 2) {
      //   this.loguid = hrefs[1]
      // }
      try {
        const rawLoguid = new URLSearchParams(
          window.location.hash.split('?')?.[1]
        ).get('loguid')
        if (rawLoguid) {
          const loguid = crypto.decrypt(rawLoguid).split('.')[0]
          if (/^[a-zA-Z0-9]+$/.test(loguid)) {
            this.loguid = loguid
          } else {
            console.error(`[NpaLogin.onLoad] Invalid loguid:`, rawLoguid)
          }
        }
      } catch (err) {
        console.error(`[NpaLogin.onLoad] `, err)
        return
      }
    },
    async ssoLogin() {
      console.log(`[ssoLogin] user:`, this.loguid)

      if (!this.loguid) {
        this.errMsg = this.$t('login_npa_hint')
        return
      }

      // 取得localStorage的該帳號uuid，若沒有則產生新的uuid並回存localStorage
      const uuids = JSON.parse(localStorage.getItem('uuids')) || {}
      if (!uuids[this.loguid]) {
        uuids[this.loguid] = generateUUID()
        localStorage.setItem('uuids', JSON.stringify(uuids))
      }

      let data = {
        loguid: this.loguid,
        deviceId: uuids[this.loguid],
      }
      // console.log(`[ssoLogin] data:`, data)

      // 串接 API
      try {
        const res = await apiSsoLogin(data)

        if (!apiCheckStatus(res)) throw res
        if (!res.data.accessToken) {
          this.$store.commit('login', false)
          this.$router.push('/npa-login')
          throw res
        }

        // ----------
        // console.log(`[ssoLogin] res.data:`, res.data)
        // 當登入的user與前一登入user不同時，須清除前一位在vuex的資料
        let lastUser = this.userFlatObj
        if (lastUser && this.userId !== lastUser.id) {
          this.$store.dispatch('resetState')
        }

        this.$store.commit('updateSsoLogin', true)
        this.$store.commit('updatePermissionV2', res.data.user.permissionV2)
        this.$store.commit('setAccessToken', res.data.accessToken)
        this.$store.commit('updateStaff', res.data.user.staff)
        localStorage.setItem('refreshToken', crypto.encrypt(res.data.refreshToken))

        // 登入成功 -------------------------------------------------------------
        this.$store.commit('login', true)
        await this.getSettingGeneral()
        await this.getWebAnnouncements()
        // 登入成功後，呼叫API取得共用的資料
        await this.fetchDataAfterLogin()
        this.getUser()

        console.log(`[ssoLogin] Go To /`)
        this.$router.push('/')
      } catch (err) {
        console.error(`[ssoLogin] err:`, err)

        this.errMsg = err.response
          ? this.$t(apiErrorMsg(err.response))
          : this.$t('api_error')
      }
    }
  },
  created() {},
  mounted() {
    this.onLoad()
    this.ssoLogin()
  },
  beforeDestroy() {
    this.userId = null
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.sso-login-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #151b35;
}

.block-wrap {
  width: 45%;
  height: 55%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;
    // background-color: #00f;

    .image {
      width: calc(100vw * 0.68);
      height: calc(100vh * 0.24);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .error-Msg {
    height: 45%;
    font-size: px2rem(20);
    text-align: center;
    color: #ff5722;
    // background-color: #ffffff11;
  }
}
</style>
